<template>
  <el-dialog
      title="添加位置"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="库存位置" prop="positionName">
            <el-input v-model="form.positionName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="所在店铺" prop="shopId">
            <el-select v-model="form.shopId" placeholder="所在店铺">
              <el-option
                  v-for="item in shopIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addPositionCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "position-add",
  data() {
    return {
      form: {
        //公司名
        positionName: "",
        shopId: "",
        isValid:1,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        positionName: [
          {required: true, message: '请选择渠道类型', trigger: 'change'},
        ],
        shopId: [
          {required: true, message: '请选择所在店铺', trigger: 'change'},
        ],
      },
      shopIdArray:[],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryShopIds();
  },
  methods: {
    //检查订单校验
    addPositionCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addPosition();
        }
      })
    },
    //添加店铺
    addPosition: function () {
      let value = "是否添加该位置?"
      this.$confirm(value, '添加位置:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/position/savePosition",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询所有一级渠道
    queryShopIds(){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopIdArray=JSON.parse(response.data.data);
      })
    },

    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>